(function($) {

	$.fn.initSearchForm = function(params) {

		var searchTimeout;

		this.find('.search-field').typeahead({
			'minLength': 3,
			'highlight': true,
		}, {
			'async': true,
			'limit': 10,
			'display': 'text',
			'templates': {
				'suggestion': function(item) {
					var html = "<div class='result'><i class='icon fimexicons-type-" + item.type + "'></i><span>" + item.text + "</span></div>";
					return html;
				},
				'notFound': '<div class="nothing-found">Nic nebylo nalezeno...</div>'
			},
			'source': function(query, syncResults, asyncResults) {

				if (searchTimeout) {
					window.clearTimeout(searchTimeout);
					searchTimeout = null;
				}

				searchTimeout = window.setTimeout(function() {
					$.get(
						params.source,
						{
							'term': query
						},
						function(resp) {
							asyncResults(resp);
						},
						'json'
					);
				}, 500)

			}
		}).on('typeahead:select', function(event, item) {
			if (item) {
				window.location = item.link;
			}
		});

		return this;

	}

})(jQuery);

(function($) {

	$.fn.initPhotoswipeLinks = function() {

		var _this;

		var items = [];

		this.each(function() {
			var $this = $(this);
			items.push({
				src: $this.attr("href"),
				w: +$this.attr("data-width"),
				h: +$this.attr("data-height"),
				title: $this.attr("title"),
				sourceNode: this
			});
		});

		this.click(function() {

			var $this = $(this);

			var pswpElement = $("#pswp")[0];

			var index = 0;
			$.each(items, function(i, item) {
				if (item.sourceNode == $this[0]) {
					index = i;
				}
			})

			var options = {
				index: index,
				showHideOpacity: true,
				bgOpacity: 0.9,
				loop: false,
				history: false,
				shareEl: false
			};

			var gallery = new PhotoSwipe(pswpElement, PhotoSwipeUI_Default, items, options);
			gallery.init();

			return false;
		});

		return this;
	};

})(jQuery);

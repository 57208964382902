(function($) {

	$.fn.initFilterPanel = function() {

		var _this = this;

		_this.find('[data-function="filter-panel"]').click(function() {
			_this.toggleClass('filter-panel--open');
			return false;
		});

		return this;

	}

})(jQuery);

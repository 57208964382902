$(function() {
	$.fn.dataTable.defaults.language = {
		"sEmptyTable":     "Tabulka neobsahuje žádná data",
		"sInfo":           "Zobrazuji _START_ až _END_ z celkem _TOTAL_ řádků",
		"sInfoEmpty":      "Tabulka je prázdná.",
		"sInfoFiltered":   "(filtrováno z celkem _MAX_ záznamů)",
		"sInfoPostFix":    "",
		"sInfoThousands":  " ",
		"sLengthMenu":     "Počet řádků na stránku: _MENU_",
		"sLoadingRecords": "Načítám...",
		"sProcessing":     "Provádím...",
		"sSearch":         "Hledat:",
		"sZeroRecords":    "Žádné záznamy neodpovídají.",
		"oPaginate": {
			"sFirst":    "První",
			"sLast":     "Poslední",
			"sNext":     "Další",
			"sPrevious": "Předchozí"
		},
		"oAria": {
			"sSortAscending":  ": aktivujte pro řazení sloupce vzestupně",
			"sSortDescending": ": aktivujte pro řazení sloupce sestupně"
		}
	}
});


(function($) {
	$.fn.initNavbar = function() {

		var _this = this;

		this.find(".icon-search").click(function() {
			$(_this).toggleClass('show-search-form');
			return false;
		});

		this.find(".js-print-button").click(function() {
			window.print();
			return false;
		})

		return this;

	}
})(jQuery);

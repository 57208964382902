(function($) {

	$.fn.initKeyboardDatatable = function(params) {

		var $field = this.find(".dataTables_filter input");
		var $table = this.find(".dataTable tbody");
		var $paginationPrev = this.find(".dataTables_paginate .previous");
		var $paginationNext = this.find(".dataTables_paginate .next");

		var api = $table.closest("table").dataTable().api();

		var keyDown = 40;
		var keyUp = 38;
		var keyLeft = 37;
		var keyRight = 39;
		var keyEnter = 13;
		var keyEscape = 27;

		//$field.on("keyup", function(e) {
		$(document.body).on("keydown", function(e) {

			// Posun kurzorem nahoru a dolů
			if (e.keyCode == keyDown || e.keyCode == keyUp) {
				var $focused = $table.find(".focused-row");
				var $nextFocused = null;
				if ($focused.length) {
					if (e.keyCode == keyDown) {
						$nextFocused = $focused.next();
						if (!$nextFocused.length) {
							$nextFocused = $table.find("tr").first();
						}
					} else {
						$nextFocused = $focused.prev();
						if (!$nextFocused.length) {
							$nextFocused = $table.find("tr").last();
						}
					}
				} else {
					if (e.keyCode == keyDown) {
						$nextFocused = $table.find("tr").first();
					} else {
						$nextFocused = $table.find("tr").last();
					}
				}
				$focused.removeClass("focused-row");
				$nextFocused.addClass("focused-row");
				e.preventDefault();
				return false;
			}

			// Stránkování doleva a doprava
			if (e.keyCode == keyRight || e.keyCode == keyLeft) {

				var $focused = $table.find(".focused-row");

				if ($focused.length) {
					var recreateFocused = false;
					var pageInfo = api.page.info();
					if (e.keyCode == keyRight) {
						if (pageInfo.pages > pageInfo.page + 1) {
							recreateFocused = true;
							api.page('next').draw('page');
						}
					}
					if (e.keyCode == keyLeft) {
						if (pageInfo.page > 0) {
							recreateFocused = true;
							api.page('previous').draw('page');
						}
					}

					if (recreateFocused) {
						window.setTimeout(function() {
							$table.find(".focused-row").removeClass("focused-row");
							$table.find("tr").first().addClass("focused-row");
						}, 100);
					}

					return false;
				}

			}

			// Zrušení
			if (e.keyCode == keyEscape) {
				var $focused = $table.find(".focused-row");
				if ($focused.length) {
					$focused.removeClass("focused-row");
				} else {
					$field.val("");
				}
				return false;
			}

			// Potvrzení
			if (e.keyCode == keyEnter) {
				$focused = $table.find(".focused-row");
				if ($focused.length) {
					var $pl = $focused.find("a[data-primary-link]");
					if ($pl.length) {
						window.location = $pl.attr("href");
						return false;
					}
				} else {
					var $pl = $table.find("a[data-primary-link]");
					if ($pl.length == 1) {
						window.location = $pl.attr("href");
						return false;
					}
				}
			}


		});

		return this;

	};

})(jQuery);

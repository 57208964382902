(function($) {

	var isMac = navigator.userAgent.match(/macintosh/i);
	var ctrlKeyName = !isMac ? 'ctrlKey' : 'metaKey';

	$.fn.initSearchHotkey = function(params) {
		params = $.extend({
			mainField: null,
			localField: null
		}, params)

		if (params.mainField && !params.mainField.length) {
			params.mainField = null;
		}
		if (params.localField && !params.localField.length) {
			params.localField = null;
		}

		this.on('keydown', function(e) {

			if (e.keyCode == 70) {
				// 70 = F
				if (e[ctrlKeyName]) {

					if (params.localField) {
						if (params.localField.is(":focus")) {
							params.mainField.focus();
						} else {
							params.localField.focus();
						}
					} else {
						params.mainField.focus();
					}

					e.preventDefault();
				}
			}
		});

		return this;
	}

})(jQuery);

(function($) {

	$.fn.fixCheckboxList = function() {

		this.find("label").each(function() {
			var $next = $(this).next();
			if ($next.is("br")) {
				$next.remove();
			}
			$(this).wrap($("<div class='checkbox'></div>"));
		});

		return this;

	};

})(jQuery);

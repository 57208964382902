(function($) {

	$.fn.initLogTable = function() {
		var _this = this;
		this.find('.click-to-expand').click(function() {
			var id = $(this).closest('tr').attr('data-log-id');
			$(this).toggleClass('expanded');
			var $target = _this.find('.expandable-row[data-log-id="' + id + '"]').toggleClass('expanded');
			return false;
		});
		this.find('.expander-link a').click(function() {
			_this.addClass('full-height');
			return false;
		});
		this.find('.table-wrapper').each(function(index, table) {
			if ($(table).height() < 370) {
				_this.addClass('short-height');
			}
		})
		return this;
	};

})(jQuery);
